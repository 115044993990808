<template>
  <v-container>
    <v-alert v-if="hasDuplicates" type="warning"
      >There is a duplicate entry for this organisation in the subgroup</v-alert
    >
    <ValidationObserver ref="objects">
      <v-form slot-scope="{ invalid, dirty }">
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="Name" rules="required|min:2|max:255">
              <v-text-field
                v-model="organisationLocal.title"
                :disabled="loading"
                label="Name"
                :error-messages="errors"
                @change="onInputChanged"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Type" rules="required">
              <v-select
                v-model="organisationLocal.organisation_types_id"
                :disabled="loading"
                :items="lists.types"
                :menu-props="{ maxHeight: '400' }"
                label="Type"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                :readonly="!isNewRecord()"
                :clearable="!isNewRecord"
                @change="onInputChanged"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Status" rules="required">
              <v-select
                v-model="organisationLocal.organisation_statuses_id"
                :disabled="loading"
                :items="lists.status"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                :error-messages="errors"
                slot-scope="{ errors }"
                label="Status"
                clearable
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Branch">
              <v-select
                v-model="organisationLocal.branch_id"
                :disabled="loading"
                :items="lists.branches"
                :menu-props="{ maxHeight: '400' }"
                item-text="title"
                item-value="id"
                label="Branch"
                clearable
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-select>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Phone">
              <v-text-field
                v-model="organisationLocal.phone"
                :disabled="loading"
                :label="phoneLabel"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Mobile">
              <v-text-field
                v-model="organisationLocal.mobile"
                :disabled="loading"
                :label="mobileLabel"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Email">
              <v-text-field
                v-model="organisationLocal.email"
                :disabled="loading"
                label="Email"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row v-if="isFamily">
          <v-col cols="12" md="4">
            <ValidationProvider name="Pets Indoor">
              <v-text-field
                v-model="organisationLocal.pets_indoor"
                :disabled="loading"
                label="Pets Indoor"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Pets Outdoor">
              <v-text-field
                v-model="organisationLocal.pets_outdoor"
                :disabled="loading"
                label="Pets Outdoor"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="4">
            <ValidationProvider name="Family Interest">
              <v-text-field
                v-model="organisationLocal.family_interest"
                :disabled="loading"
                label="Family Interest"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Transport and Costs">
              <v-text-field
                v-model="organisationLocal.transport"
                :disabled="loading"
                label="Transport and Costs"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <ValidationProvider name="Street Address">
              <AddressField label="Street Address" v-model="organisationLocal.street_address" :disabled="loading" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Postal Address">
              <AddressField label="Postal Address" v-model="organisationLocal.postal_address" :disabled="loading" />
            </ValidationProvider>
          </v-col>
          <v-col cols="12" md="6">
            <ValidationProvider name="Postal Address same">
              <v-switch
                v-model="organisationLocal.same_address"
                :disabled="loading"
                label="Postal Address same as Street Address"
                :error-messages="errors"
                slot-scope="{ errors }"
              ></v-switch>
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Notes">
              <v-textarea
                v-model="organisationLocal.notes"
                label="Notes"
                rows="3"
                :error-messages="errors"
                slot-scope="{ errors }"
                filled
                auto-grow
              ></v-textarea>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-btn @click="saveAction" :disabled="loading || invalid || !dirty" class="primary">Save</v-btn>
      </v-form>
    </ValidationObserver>
    <ConfirmDialog :show="showNavigateAwayConfirmDialog" @confirm-dialog-clicked="confirmNavigate" />
  </v-container>
</template>

<script>
import loadDash from 'lodash';
import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, max, min } from 'vee-validate/dist/rules';
import { mapGetters } from 'vuex';
import store from '@/store/index';
import OrganisationConstants from '@/store/constants/Organisation';
import AddressField from '@/views/AddressField';
import ConfirmDialog from '@/views/dialogs/ConfirmDialog';
import SubgroupService from '@/services/SubgroupService';

extend('required', required);
extend('max', max);
extend('min', min);

export default {
  name: 'OrganisationDetailForm',
  beforeRouteLeave(to, from, next) {
    if (this.isDirty()) {
      this.next = next;
      this.showNavigateAwayConfirmDialog = true;
    } else {
      next();
    }
  },
  components: {
    ConfirmDialog,
    AddressField,
    ValidationProvider,
    ValidationObserver
  },
  methods: {
    /**
     * Handle firstname and surname on change
     */
    onInputChanged() {
      this.checkForDuplicates();
      this.setLabelNamesBasedOnOrganisationType();
    },
    /**
     * Check for duplicates in the system
     */
    async checkForDuplicates() {
      const subgroup_id = parseInt(this.$route.query.subgroup_id);
      if (subgroup_id > 0 && this.organisationLocal.title != '') {
        const duplicates = await SubgroupService.hostDuplicates(
          subgroup_id,
          this.organisationLocal.id,
          this.organisationLocal.title
        );
        if (duplicates && duplicates.data.data.length > 0) {
          this.hasDuplicates = true;
        } else {
          this.hasDuplicates = false;
        }
      }
    },
    /**
     * Save method for the form
     */
    async saveAction() {
      const result = await this.$refs.objects.validate();
      if (result) {
        store.dispatch('organisation/save', {
          id: this.organisationLocal.id,
          organisation: this.organisationLocal
        });
      }
      this.resetValidation();
    },
    /**
     * Check if this a new record
     */
    isNewRecord() {
      return this.$route.params.id == 'new' ? true : false;
    },
    /**
     * Reset the validation, this will make sure the dirty flags get set to
     * false after save action.
     */
    resetValidation() {
      requestAnimationFrame(() => {
        this.$refs.objects.reset();
      });
    },
    /**
     * Check if the form has changed after loading
     */
    isDirty() {
      return this.$refs.objects._data.flags.dirty;
    },
    /**
     * Check if organisation is host family
     */
    // isFamily() {
    //   return this.organisationLocal.organisation_types_id == OrganisationConstants.HOST_FAMILY ? true : false;
    // },
    /**
     * Confirm navigation on dirty page
     */
    confirmNavigate: function (navigateAway) {
      this.showNavigateAwayConfirmDialog = false;
      if (navigateAway) {
        this.next();
      }
    },
    /**
     * Set labels on phone fields depending on the organisation type
     */
    setLabelNamesBasedOnOrganisationType() {
      if (
        this.organisationLocal &&
        this.organisationLocal.organisation_types_id === OrganisationConstants.HOST_FAMILY
      ) {
        this.phoneLabel = 'HF Mobile';
        this.mobileLabel = 'HM Mobile';
        this.isFamily = true;
      } else {
        this.phoneLabel = 'Phone';
        this.mobileLabel = 'Mobile';
        this.isFamily = false;
      }
    }
  },
  computed: {
    /**
     * The mapGetters helper simply maps store getters to local computed properties:
     */
    ...mapGetters('organisation', ['loading', 'organisation', 'error', 'success', 'meta']),
    /**
     * We retrive the meta data for each requests. This allows us to update the lists
     * if they have changed in the application.
     */
    lists: function () {
      if (this.meta && this.meta.lists) {
        return this.meta.lists;
      }
      return {
        status: [],
        types: [],
        branches: []
      };
    },
    /**
     * To work with v-model and vuex, we only want to keep
     * a local copy until the user is ready to save.
     */
    organisationLocal: function () {
      const organisation = loadDash.cloneDeep(this.organisation);

      if (this.isNewRecord() && this.$route.query.organisation_types_id) {
        organisation.organisation_types_id = parseInt(this.$route.query.organisation_types_id, 10);
      }
      return organisation;
    }
  },
  watch: {
    /**
     * If organisation has been added from the subgroup we want to attach the organisation to the
     * subgroup and then redirect back to the subgroup page.
     */
    organisation: {
      handler(newValue, oldValue) {
        this.setLabelNamesBasedOnOrganisationType();
        if (newValue && oldValue && oldValue.id == 0 && newValue.id > 0 && this.$route.query.subgroup_id) {
          const subgroup_id = parseInt(this.$route.query.subgroup_id);
          if (subgroup_id > 0) {
            store.dispatch('subgroup/hostAttach', {
              subgroup_id: subgroup_id,
              organisation_id: this.organisation.id
            });

            this.$router.push({
              path: `/subgroups/${subgroup_id}/hosts`
            });
          }
        }
      },
      deep: true
    },
    'organisationLocal.organisation_types_id': {
      handler() {
        this.setLabelNamesBasedOnOrganisationType();
      }
    }
  },
  data: () => ({
    showNavigateAwayConfirmDialog: false,
    hasDuplicates: false,
    phoneLabel: 'Phone',
    mobileLabel: 'Mobile',
    isFamily: false
  })
};
</script>
